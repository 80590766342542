import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const HienTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "受注番号", value: "acceptNo" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "条長(m)", value: "length" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "ストップリング(布)", value: "stopRing" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class HienTable {
  workDate: string;
  updateTs: string | null;
  acceptNo: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  length: number | null;
  suryoMeisai: string | null;
  stopRing: number | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    acceptNo: string | null;
    specificationNo: string | null;
    itemName: string | null;
    size: string | null;
    length: number | null;
    suryoMeisai: string | null;
    stopRing: number | null;
    abnormality: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.acceptNo = props.acceptNo;
    this.specificationNo = props.specificationNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.length = props.length;
    this.suryoMeisai = props.suryoMeisai;
    this.stopRing = props.stopRing;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const HienText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  operator: { text: "作業者(操縦者)", value: "operator" },
  worker: { text: "作業者", value: "worker" },
  winding: { text: "作業者(巻取り)", value: "winding" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号完了", value: "successFlag" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  preProcessDrum: { text: "前工程ドラムNo", value: "preProcessDrum" },
  leadTemperature: { text: "作業前鉛温度(℃)", value: "leadTemperature" },
  windingDrum: { text: "巻取りドラムNo", value: "windingDrum" },
  length: { text: "条長(m)", value: "length" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  stopRing: { text: "ストップリング(布)", value: "stopRing" },
  outerDiameter: { text: "外径(mm)", value: "outerDiameter" },
  nippleDiameter: { text: "ニップル径(mm)", value: "nippleDiameter" },
  diceDiameter: { text: "ダイス径(mm)", value: "diceDiameter" },
  leadInsideDiameter: { text: "鉛内径(mm)", value: "leadInsideDiameter" },
  leadThickness: { text: "鉛厚さ(mm)", value: "leadThickness" },
  interference: { text: "締め代(mm)", value: "interference" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Hien extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  operator: string | null;
  worker: string | null;
  winding: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  preProcessDrum: string | null;
  leadTemperature: number | null;
  windingDrum: string | null;
  length: number | null;
  suryoMeisai: string | null;
  stopRing: number | null;
  outerDiameter: number | null;
  nippleDiameter: number | null;
  diceDiameter: number | null;
  leadInsideDiameter: number | null;
  leadThickness: number | null;
  interference: number | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Hien);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Hien | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Hien) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.operator = _props.operator;
      this.worker = _props.worker;
      this.winding = _props.winding;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.specificationNo = _props.specificationNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.preProcessDrum = _props.preProcessDrum;
      this.leadTemperature = _props.leadTemperature;
      this.windingDrum = _props.windingDrum;
      this.length = _props.length;
      this.suryoMeisai = _props.suryoMeisai;
      this.stopRing = _props.stopRing;
      this.outerDiameter = _props.outerDiameter;
      this.nippleDiameter = _props.nippleDiameter;
      this.diceDiameter = _props.diceDiameter;
      this.leadInsideDiameter = _props.leadInsideDiameter;
      this.leadThickness = _props.leadThickness;
      this.interference = _props.interference;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "HIEN";
    this.machineCode = null;
    this.operator = null;
    this.worker = null;
    this.winding = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.successFlag = "0";
    this.specificationNo = null;
    this.itemName = null;
    this.size = null;
    this.preProcessDrum = null;
    this.leadTemperature = null;
    this.windingDrum = null;
    this.length = null;
    this.suryoMeisai = null;
    this.stopRing = null;
    this.outerDiameter = null;
    this.nippleDiameter = null;
    this.diceDiameter = null;
    this.leadInsideDiameter = null;
    this.leadThickness = null;
    this.interference = null;
    this.abnormality = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
